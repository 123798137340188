<template>
  <div>
    <div class="card">
      <div class="bg-blue-light px-1 rounded pb-2">
        <TitleButton
            class="mt-1"
            btnTitle="List"
            :showBtn="true"
            :showAddNew="false"
            title="Add New Project"
            @onClickAddNewButton="navigateToListPage"
        />

        <div class="row mt-2 gy-1">
          <div class="row mt-1">
            <div class="col-sm-4">
              <label class="align-middle">Project Name</label>
              <input type="text" class="form-control" v-model="formData.project_name">
            </div>
            <div class="col-sm-4">
              <label class="align-middle">Project Code</label>
              <input type="text" class="form-control" v-model="formData.project_code">
            </div>
            <div class="col-sm-4">
              <label class="align-middle">Create Date</label>
              <input type="date" class="form-control" v-model="formData.create_date">
            </div>
          </div>
          <div class="row mt-1">
            <div class="col-sm-4">
              <label class="align-middle">Business Line</label>
              <v-select
                  placeholder="Select Project"
                  v-model="formData.business_line"
                  :options="businessLines"
                  label="businessLine"
                  :reducer="businessLine => businessLine.id"
              />
            </div>
            <div class="col-sm-4">
              <label class="align-middle">Start Date</label>
              <input type="date" class="form-control" v-model="formData.start_date">
            </div>
            <div class="col-sm-4">
              <label class="align-middle">End Date</label>
              <input type="date" class="form-control" v-model="formData.end_date">
            </div>
          </div>
          <div class="row mt-1">
            <div class="col-sm-4">
              <label class="align-middle">Party</label>
              <v-select
                  placeholder="Select Project"
                  v-model="formData.party_id"
                  :options="parties"
                  label="party"
                  :reducer="party => party.id"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="px-1 rounded pb-2">
        <TitleButton
            class="mt-1"
            btnTitle="Add New"
            :showBtn="false"
            :showAddNew="true"
            title="Team Member"
            @onClickAddNewButton="showNewTeamMemberModal"
        />

        <div class="card-body">
          <div class="row d-flex flex-wrap justify-content-between align-items-center">
            <div class="col-3">
              <div class="card border-1" style="width: 18rem;">
                <div class="card-body">
                  <h5 class="card-title text-center">Role</h5>
                  <img src="http://dev.miaccounts.net/images/avatar.jpg" class="card-img-top" alt="img">
                  <p class="card-text text-center mt-2">
                    User Name <br>
                    Designation
                  </p>
                </div>
              </div>
            </div>
            <div class="col-3">
              <div class="card border-1" style="width: 18rem;">
                <div class="card-body">
                  <h5 class="card-title text-center">Role</h5>
                  <img src="http://dev.miaccounts.net/images/avatar.jpg" class="card-img-top" alt="img">
                  <p class="card-text text-center mt-2">
                    User Name <br>
                    Designation
                  </p>
                </div>
              </div>
            </div>
            <div class="col-3">
              <div class="card border-1" style="width: 18rem;">
                <div class="card-body">
                  <h5 class="card-title text-center">Role</h5>
                  <img src="http://dev.miaccounts.net/images/avatar.jpg" class="card-img-top" alt="img">
                  <p class="card-text text-center mt-2">
                    User Name <br>
                    Designation
                  </p>
                </div>
              </div>
            </div>
            <div class="col-3">
              <div class="card border-1" style="width: 18rem;">
                <div class="card-body">
                  <h5 class="card-title text-center">Role</h5>
                  <img src="http://dev.miaccounts.net/images/avatar.jpg" class="card-img-top" alt="img">
                  <p class="card-text text-center mt-2">
                    User Name <br>
                    Designation
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>

    <div class="card">
      <div class="px-1 rounded pb-2">
        <TitleButton
            class="mt-1"
            btnTitle="Add New"
            :showBtn="false"
            :showAddNew="true"
            title="Project Budget"
            @onClickAddNewButton="showNewProjectBudgetModal"
        >
          <template #title-details>
            <p>Budget Amount: 2,900,000.00</p>
          </template>
        </TitleButton>
      </div>

      <div class="row gy-1">
        <div class="col-12 p-2">
          <table class="table">
            <thead>
            <tr>
              <th>Budget Head</th>
              <th>Account Head</th>
              <th>Amount</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>

    </div>

    <div class="card">
      <div class="px-1 rounded pb-2">
        <TitleButton
            class="mt-1"
            btnTitle="Add New"
            :showBtn="false"
            :showAddNew="true"
            title="Task"
            @onClickAddNewButton="showNewTaskModal"
        />
      </div>

      <div class="row gy-1">
        <div class="col-12 p-2">
          <table class="table">
            <thead>
            <tr>
              <th>Task Type</th>
              <th>Task Name</th>
              <th>Task Details</th>
              <th>Team Member</th>
              <th>Start Date</th>
              <th>End Date</th>
              <th>Progress Rate</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="mt-2 d-flex justify-content-start justify-content-sm-center px-2 gap-1 flex-wrap pb-2">
        <hr class="hr-full-width mb-1">
        <button :disabled="saveButtonLoader || saveNewButtonLoader" @click="handleSubmit(true)"
                class="btn btn-primary">
          Save
        </button>
        <button :disabled="saveButtonLoader || saveNewButtonLoader" @click="handleSubmit(false)"
                class="btn btn-primary">Save & New
        </button>
        <button @click="navigateToListPage" class="btn btn-outline-secondary">Cancel</button>
      </div>

      <GlobalLoader/>
    </div>
  </div>
</template>
<script setup>
import {inject, ref} from 'vue'
import {useRoute, useRouter} from "vue-router"
import TitleButton from '@/components/atom/TitleButton'

const router = useRouter()
const route = useRoute()
const showError = inject('showError')
const showSuccess = inject('showSuccess')

let saveButtonLoader = ref(false)
let saveNewButtonLoader = ref(false)
let parties = ref([])
let businessLines = ref([])
let formData = ref({
  project_name: null,
  project_code: null,
  create_date: null,
  business_line: null,
  start_date: null,
  end_date: null,
  party_id: null,
})

function navigateToListPage() {
  router.push({name: `requisition-list`, params: route.params, query: route.query});
}

function handleSubmit() {

}

function showNewTeamMemberModal() {

}

function showNewProjectBudgetModal() {

}

function showNewTaskModal() {

}

</script>